import { createAsyncThunk } from "@reduxjs/toolkit";
import { IApiError } from "models/apiError.model";
import { ITaskDTO, TASK_SLICE_NAME } from "./models";
import {
  ICreateProperties,
  ICreateView,
  IGetAllViewsResponse,
  IGetTasksResponse,
  IUpdatePropertyRequest,
  IViewTask,
} from "models/task.model";
import { formatApiError } from "shared/utils";
import TaskAPI from "api/TaskAPI";

export const getAllViews = createAsyncThunk<
  IGetAllViewsResponse,
  void,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/getAllViews`,
  async () => {
    const response = await TaskAPI.getAllViews();
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getTasks = createAsyncThunk<
  IGetTasksResponse,
  IViewTask,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/getTasks`,
  async (data: IViewTask) => {
    const response = await TaskAPI.getTasks(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const createProperties = createAsyncThunk<
  any,
  ICreateProperties,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/createProperties`,
  async (data: ICreateProperties) => {
    const response = await TaskAPI.createProperties(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateProperty = createAsyncThunk<
  any,
  IUpdatePropertyRequest,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/updateProperty`,
  async (data: IUpdatePropertyRequest) => {
    const response = await TaskAPI.updateProperty(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const createView = createAsyncThunk<
  any,
  ICreateView,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/createView`,
  async (data) => {
    const response = await TaskAPI.createView(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const createTask = createAsyncThunk<
  any,
  ITaskDTO,
  { serializedErrorType: IApiError }
>(
  `${TASK_SLICE_NAME}/createTask`,
  async (data: ITaskDTO) => {
    const response = await TaskAPI.createTask(data);
    return response.data;
  },
  { serializeError: formatApiError }
);
