export interface IGetAllViewsResponse {
  data: IView[];
}

export interface IView {
  _id: string;
  viewName: string;
  updatedAt: string;
}

export interface DynamicField {
  key: string;
  type: FieldType;
  icon: string;
  value: string;
}

export interface ITask {
  _id: string;
  viewId: string;
  taskName: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  [key: string]: DynamicField | string | number;
}

export interface IGetTasksResponse {
  data: ITask[];
}

export interface FilterDTO {
  key: string;
  type: string;
  value: string;
}

export interface IViewTask {
  viewId: string;
  filters: FilterDTO[];
}

export interface ICreateProperties {
  property: DynamicField;
  columnId?: string;
  viewId: string;
}

export interface IUpdatePropertyRequest {
  oldPropertyKey: string;

  newProperty: {
    key?: string;
    type?: FieldType;
    icon?: string;
  };
  viewId: string;
}

export interface ICreateView {
  viewName: string;
  hiddenProperties: string[];
  groupBy: string[];
  order: string[];
  filters: string[];
}

export enum FieldType {
  Text = "text",
  Number = "number",
  Select = "select",
  MultiSelect = "multi-select",
  Status = "status",
  Date = "date",
  Person = "person",
  Files = "files",
  Checkbox = "checkbox",
  Url = "url",
  Email = "email",
  Phone = "phone",
  Formula = "formula",
  Relation = "relation",
  Rollup = "rollup",
  CreatedTime = "createdTime",
  CreatedBy = "createdBy",
  LastEditedTime = "lastEditedTime",
  LastEditedBy = "lastEditedBy",
  Button = "button",
  Id = "id",
  Null = "null",
}
