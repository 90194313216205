import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  initialState,
  TASK_SLICE_NAME,
  TaskState,
  Column,
  Row,
  FilterValue,
} from "./models";
import {
  createProperties,
  createTask,
  createView,
  getAllViews,
  getTasks,
  updateProperty,
} from "./actionCreators";
import { transformDataDynamic } from "shared/utils/transformDataDynamic";
import { FieldType } from "models/task.model";

const taskSlice = createSlice({
  name: TASK_SLICE_NAME,
  initialState,
  reducers: {
    setActiveTask(state, action) {
      state.activeTask = action.payload;
    },
    addOptionToColumn(
      state,
      action: PayloadAction<{
        columnId: string;
        option: string;
        backgroundColor: string;
      }>
    ) {
      const { columnId, option, backgroundColor } = action.payload;
      const column = state.columns.find((col) => col.id === columnId);
      if (column) {
        column.options.push({ label: option, backgroundColor });
      }
    },
    addRow(state) {
      state.data.push({});
    },
    updateColumnType(
      state,
      action: PayloadAction<{
        columnId: string;
        dataType: FieldType;
      }>
    ) {
      const { columnId, dataType } = action.payload;
      const column = state.columns.find((col) => col.id === columnId);
      if (column && column.dataType !== dataType) {
        column.dataType = dataType;
      }
    },
    updateColumnHeader(
      state,
      action: PayloadAction<{ columnId: string; label: string }>
    ) {
      const { columnId, label } = action.payload;
      const column = state.columns.find((col) => col.id === columnId);
      if (column) {
        column.label = label;
      }
    },
    updateCell(
      state,
      action: PayloadAction<{ rowIndex: number; columnId: string; value: any }>
    ) {
      const { rowIndex, columnId, value } = action.payload;
      state.data[rowIndex][columnId] = value;
    },
    addColumnToLeft(
      state,
      action: PayloadAction<{ columnId: string; focus?: boolean }>
    ) {
      const { columnId } = action.payload;
      const index = state.columns.findIndex((col) => col.id === columnId);
      if (index !== -1) {
        state.columns.splice(index, 0, {
          id: `new_${Date.now()}`,
          label: "",
          accessor: "",
          icon: "",
          dataType: FieldType.Text,
          created: true,
          options: [],
        });
      }
    },
    addColumnToRight(
      state,
      action: PayloadAction<{ columnId: string; focus?: boolean }>
    ) {
      const { columnId } = action.payload;
      const index = state.columns.findIndex((col) => col.id === columnId);
      if (index !== -1) {
        state.columns.splice(index + 1, 0, {
          id: `new_${Date.now()}`,
          label: "",
          accessor: "",
          icon: "",
          dataType: FieldType.Text,
          created: true,
          options: [],
        });
      }
    },
    deleteColumn(state, action: PayloadAction<{ columnId: string }>) {
      const { columnId } = action.payload;
      state.columns = state.columns.filter((col) => col.id !== columnId);
    },
    enableReset(state) {
      state.skipReset = false;
    },
    updateState(
      state,
      action: PayloadAction<{
        columns: Column[];
        data: Row[];
        skipReset: boolean;
      }>
    ) {
      const { columns, data, skipReset } = action.payload;
      state.columns = columns;
      state.data = data;
      state.skipReset = skipReset;
    },
    setSelectedOptionIndex(state, action: PayloadAction<number>) {
      state.selectedOptionIndex = action.payload;
    },
    setIsMenuOpen(state, action: PayloadAction<boolean>) {
      state.isMenuOpen = action.payload;
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload;
    },
    setDrawerVisible(state, action: PayloadAction<boolean>) {
      state.drawerVisible = action.payload;
    },
    setActiveFilters(state, action: PayloadAction<FilterValue[]>) {
      state.activeFilters = action.payload;
    },
    setModalPosition(state, action: PayloadAction<{ x: number; y: number }>) {
      state.modalPosition = action.payload;
    },
    removeFilter(state, action: PayloadAction<number>) {
      state.activeFilters = state.activeFilters.filter(
        (_, i) => i !== action.payload
      );
    },
    addFilter(state, action: PayloadAction<string>) {
      state.activeFilters.push({ column: action.payload, value: [] });
    },
    setViewCreate(state, action: PayloadAction<boolean>) {
      state.isViewCreateModalOpen = action.payload;
    },
    updateFilter(
      state,
      action: PayloadAction<{ index: number; newValue: string | string[] }>
    ) {
      const { index, newValue } = action.payload;
      state.activeFilters[index].value = Array.isArray(newValue)
        ? newValue
        : [newValue];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(getAllViews), (state: TaskState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.views = payload.data;
      })
      .addMatcher(isFulfilled(getTasks), (state: TaskState, action) => {
        const { meta, payload } = action;
        state.activeTask = meta.arg.viewId;
        state.isLoading = false;
        const transformedData = transformDataDynamic(payload.data);
        state.columns = transformedData.columns;
        state.data = transformedData.data;
      })
      .addMatcher(isFulfilled(updateProperty), (state: TaskState, action) => {
        const { oldPropertyKey, newProperty } = action.meta.arg;
        console.log(oldPropertyKey, newProperty, "updateProperty");
        const column = state.columns.find((col) => col.id === oldPropertyKey);
        if (column) {
          column.label = newProperty.key;
          column.accessor = newProperty.key;
          column.id = newProperty.key;

          if (newProperty.icon) {
            column.icon = newProperty.icon;
          }

          if (newProperty.type) {
            column.dataType = newProperty.type;
          }
        }
      })
      .addMatcher(isFulfilled(createProperties), (state: TaskState, action) => {
        const { property, columnId } = action.meta.arg;

        const index = state.columns.findIndex((col) => col.id === columnId);
        if (index !== -1) {
          state.columns.splice(index, 0, {
            id: property.key,
            label: property.key,
            accessor: property.key,
            dataType: property.type,
            icon: property.icon,
            created: true,
            options: [],
          });
        }
      })
      .addMatcher(isFulfilled(createView), (state: TaskState, action) => {
        const { payload } = action;
        state.isViewCreateModalOpen = false;
        state.isLoading = false;
      })
      .addMatcher(isFulfilled(createTask), (state: TaskState, action) => {
        const newTask = action.payload;
        state.data.push(newTask);
      })
      .addMatcher(
        isRejected(
          getAllViews,
          getTasks,
          updateProperty,
          createProperties,
          createView,
          createTask
        ),
        (state: TaskState, action) => {
          const { error } = action;
          state.error = error;
          state.isLoading = false;
        }
      )
      .addMatcher(
        isPending(
          getAllViews,
          getTasks,
          updateProperty,
          createProperties,
          createView,
          createTask
        ),
        (state: TaskState) => {
          state.error = null;
          state.isLoading = true;
        }
      );
  },
});
export const {
  addOptionToColumn,
  addRow,
  updateColumnType,
  updateColumnHeader,
  updateCell,
  addColumnToLeft,
  addColumnToRight,
  deleteColumn,
  enableReset,
  updateState,
  setSelectedOptionIndex,
  setIsMenuOpen,
  setShowFilters,
  setDrawerVisible,
  setActiveFilters,
  setModalPosition,
  removeFilter,
  addFilter,
  updateFilter,
  setViewCreate,
} = taskSlice.actions;
export default taskSlice.reducer;
