import { createTheme, PaletteOptions } from "@mui/material/styles";

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      light: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      light?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFD75D", // Primary button background color
      contrastText: "#2D4764", // Primary button text color
      light: "#E6E7EA", // Light shade for primary
    },
    secondary: {
      main: "#2D4764", // Secondary button background color
      contrastText: "#FFFFFF", // Secondary button text color
      light: "#E6E7EA", // Light shade for secondary
    },
    info: {
      main: "#E6E7EACC",
      contrastText: "#2D4764",
      light: "#E6E7EACC",
    },
    custom: {
      light: "#E6E7EACC", // Add a custom color for general usage
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Custom border radius for all buttons
        },
        containedPrimary: {
          color: "#2D4764", // Primary button text color
          backgroundColor: "#FFD75D", // Primary button background color
          "&:hover": {
            backgroundColor: "#FFC845", // Slightly darker shade for hover
          },
        },
        containedSecondary: {
          color: "#FFFFFF", // Secondary button text color
          backgroundColor: "#2D4764", // Secondary button background color
          "&:hover": {
            backgroundColor: "#1B2E42", // Slightly darker shade for hover
          },
        },
        outlined: {
          borderColor: "#E6E7EA", // Use light color for outlined buttons
          color: "#2D4764", // Text color for outlined buttons
          "&:hover": {
            backgroundColor: "#E6E7EA", // Light background on hover
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "unset"
        },
      },
    },
  },
});

export default theme;
