import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup, {
  ButtonGroupPropsColorOverrides,
} from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { OverridableStringUnion } from "@mui/types";

interface DropdownButtonProps {
  items: string[];
  selectedIndex: number;
  onClick: (selectedIndex: number) => void;
  disabledIndexes?: number[];
  buttonLabel?: string;
  startIcon?: React.ReactNode;
  color?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning",
    ButtonGroupPropsColorOverrides
  >;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  items,
  selectedIndex,
  onClick,
  disabledIndexes = [],
  buttonLabel = "Select an option",
  startIcon,
  color = "secondary",
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    onClick(selectedIndex);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    onClick(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        color={color}
      >
        <Button onClick={handleClick} startIcon={startIcon}>
          {items[selectedIndex] || buttonLabel}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "dropdown-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select an option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="dropdown-button-menu" autoFocusItem>
                  {items.map((item, index) => (
                    <MenuItem
                      key={item}
                      disabled={disabledIndexes.includes(index)}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default DropdownButton;
