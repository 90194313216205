import { FieldType } from "models/task.model";

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

// Define types for Data Row and Column
type Row = {
  [key: string]: any;
};

type Option = {
  label: string;
  backgroundColor: string;
};

type Column = {
  id: string;
  label: string;
  icon?: string;
  accessor?: string;
  minWidth?: number;
  width?: number;
  dataType: FieldType
  options: Option[];
  disableResizing?: boolean;
};

type TableData = {
  columns: Column[];
  data: Row[];
  skipReset: boolean;
};

// Function to transform input data into the required format
export function transformDataDynamic(inputData: any[]): TableData {
  if (inputData.length === 0)
    return {
      columns: [
        {
          id: "999999",
          label: "+",
          width: 20,
          dataType: FieldType.Null,
          options: [],
          disableResizing: true,
        },
      ],
      data: [],
      skipReset: false,
    };

  console.log(inputData, "inputData");

  const columns: Column[] = Object.keys(inputData[0])
    .filter(
      (key) =>
        key !== "_id" &&
        key !== "viewId" &&
        key !== "__v" &&
        key !== "createdAt" &&
        key !== "updatedAt"
    )
    .map((key) => {
      if (
        typeof inputData[0][key] === "object" &&
        inputData[0][key]?.value !== undefined
      ) {
        const dataType = inputData[0][key]?.type;
        return {
          id: key,
          label: key.charAt(0).toUpperCase() + key.slice(1),
          accessor: key,
          dataType,
          icon: inputData[0][key]?.icon,
          options:
            dataType === FieldType.Select || dataType === FieldType.MultiSelect
              ? inputData.map((item) => ({
                  label: item[key]?.value,
                  backgroundColor: randomColor(),
                }))
              : [],
        };
      }
      //  else if (
      //   typeof inputData[0][key] === "string" ||
      //   typeof inputData[0][key] === "number"
      // ) {
      //   return {
      //     id: key,
      //     label: key.charAt(0).toUpperCase() + key.slice(1),
      //     accessor: key,
      //     dataType: typeof inputData[0][key] === "string" ? "text" : "number",
      //     options: [],
      //   };
      // }
      return {
        id: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        accessor: key,
        dataType: "null",
        options: [],
      };
    });

  columns.push({
    id: "999999",
    label: "+",
    width: 20,
    dataType: FieldType.Null,
    options: [],
    disableResizing: true,
  });

  const data: Row[] = inputData.map((item) => {
    const row: Row = {};
    Object.keys(item).forEach((key) => {
      if (key !== "_id" && key !== "viewId") {
        row[key] = item[key]?.value ?? item[key];
      }
    });
    return row;
  });

  return { columns, data, skipReset: false };
}
